import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'TaskStatus',
    })
  },
}
