// Pathify
import { make } from 'vuex-pathify'

// Globals
import { IN_BROWSER } from '@/util/globals'
import axios from 'axios'
import taskStatusApi from '@/api/task-status'

const state = {
  taskStatus: [],
  taskStatusLoad: 0,
}

const mutations = make.mutations(state)

const actions = {
  setGet: ({ commit }) => {
    return new Promise((resolve, reject) => {
      commit('taskStatusLoad', 0)
      taskStatusApi.index().then(response => {
        const result = response.data.data
        commit('taskStatus', result)
        commit('taskStatusLoad', 1)
        resolve(true)
      }).catch(error => {
        commit('taskStatusLoad', 3)
        reject(error)
      })
    })
  },
}

const getters = {
  taskStatus: state => {
    const result = [{ id: 0, name: 'All' }]
    state.taskStatus.forEach((element) => {
      result.push(element)
    })
    return result
  },
  taskStatusNoAll: state => {
    return state.taskStatus
  },
  taskStatusLoad: state => {
    return state.taskStatusLoad
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
