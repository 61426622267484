// Imports
import Vue from 'vue'
import Router from 'vue-router'
import userApi, { permission } from '@/api/user'
import { trailingSlash } from '@/util/helpers'
import {
  error,
  layout,
  route,
} from '@/util/routes'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    layout('Default', [
      route('Dashboard'),
      // Auth
      route('Login', null, 'login'),
      // Pages
      route('UserProfile', null, 'components/profile'),

      // Components
      route('Notifications', null, 'components/notifications'),
      route('Icons', null, 'components/icons'),
      route('Typography', null, 'components/typography'),
      // Tables
      route('Manual', null, 'tables/manual'),
      route('Item Receipt', null, 'tables/itemReceipt'),
      route('Testing Report', null, 'tables/testingReport', { requiredPermission: '603' }),
      route('Item Table', null, 'tables/items'),
      route('Item Type', null, 'tables/itemType'),
      route('Item/Management', null, 'item/management/:id'),
      route('Receipt Show', null, 'receipt/:id'),
      route('Receipt Item', null, 'receiptItem/:id'),
      route('Receipt/CreateEditManual', null, 'createEditManual/:id'),
      route('Equipment Record', null, 'tables/equipmentRecord'),
      route('Equipment Model', null, 'tables/equipmentModel'),
      route('Configuration List', null, 'tables/configurationList'),
      // Calendar
      route('Calendar', null, 'calendar'),
      // RMA Report
      route('Rma/SalesRma', null, 'rma/SalesRma'),
      // Maps
      route('Google Maps', null, 'maps/google'),
      // Admins
      route('Admins/UserManagement', null, 'admins/UserManagement', { requiredPermission: '902' }),
      route('Admins/ItemCategory', null, 'admins/ItemCategory'),
      route('Admins/ItemProperty', null, 'admins/ItemProperty'),
      route('Admins/SystemSetting', null, 'admins/SystemSetting'),
      // Error
      route('Error401', null, '/Error401'),
    ]),
  ],
})

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.requiredPermission) {
    permission(to.meta.requiredPermission).then(response => {
      const auth = response.data
      if (auth === true || auth === 'true') {
        return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
      } else {
        next({ name: 'Error401' })
      }
    }).catch(() => { })
  } else {
    return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
  }
})

export default router
