import moment from 'moment'
export function itemReceiptIndex (data) {
    data.forEach((itemReceipt, index) => {
      itemReceipt.item_receipt_date = moment.utc(itemReceipt.item_receipt_date).local().format('YYYY-MMM-DD')
      itemReceipt.item_first_due_date = moment.utc(itemReceipt.item_first_due_date).local().format('YYYY-MMM-DD')
        const items = itemReceipt.items
        items.forEach((item, key) => {
          item.tfb = item.tested_quantity + '/' + item.failed_in_testing + '/' + item.failed_in_receiving
          item.updated_at = moment.utc(item.updated_at).local().format('YYYY-MMM-DD HH:ss')
          item.estimate_due_date = moment.utc(item.estimate_due_date).local().fromNow()
          if (item.is_request) {
            item.request_due_date = moment.utc(item.request_due_date).local().fromNow()
          }
          item.is_request ? item.remain_days = item.request_due_date : item.remain_days = item.estimate_due_date
          if (item.sos_line_ids && item.sos_line_ids.length > 0) {
            item.sos_line_info = ' '
            item.sos_line_ids.forEach((element, key) => {
              item.sos_line_info = item.sos_line_info + element.qty
              if ((key + 1) < item.sos_line_ids.length) item.sos_line_info = item.sos_line_info + '/'
            })
          } else {
            item.sos_line_info = 'NA'
          }
        })
    })
    return data
}

export function singleItemReceipt (data) {
  data.item_receipt_date = moment.utc(data.item_receipt_date).local().format('YYYY-MM-DD')
  data.updated_at = moment.utc(data.updated_at).local().format('YYYY-MMM-DD HH:ss')
  data.created_at = moment.utc(data.created_at).local().format('YYYY-MMM-DD HH:ss')
  data.item_first_due_date = moment.utc(data.item_first_due_date).local().fromNow()
  data.request_due_date ? data.request_due_date = moment.utc(data.item_first_due_date).local().fromNow() : data.request_due_date = null
  data.item_receipt_item_comment = []
  data.items.forEach((item, key) => {
    item.updated_at = moment.utc(item.updated_at).local().format('YYYY-MMM-DD HH:ss')
    item.item_first_due_date = moment.utc(item.item_first_due_date).local().fromNow()
    if (item.comment.length > 0) {
      item.comment.forEach((singleComment, commentKey) => {
        data.item_receipt_item_comment.push({
          id: singleComment.id,
          item: item.item.part_number,
          content: singleComment.content,
          created_at: moment.utc(singleComment.created_at).local().format('YYYY-MMM-DD HH:ss'),
          updated_at: moment.utc(singleComment.updated_at).local().format('YYYY-MMM-DD HH:ss'),
          reply_type: singleComment.reply_type,
          user: singleComment.user,
        })
      })
    }
    if (item.sos_line_ids && item.sos_line_ids.length > 0) {
      item.sos_line_info = ' '
      item.sos_line_ids.forEach((element, key) => {
        item.sos_line_info = item.sos_line_info + element.qty
        if ((key + 1) < item.sos_line_ids.length) item.sos_line_info = item.sos_line_info + '/'
      })
    } else {
      item.sos_line_info = 'NA'
    }
  })
  data.comment.forEach((comment, key) => {
    comment.updated_at = moment.utc(comment.updated_at).local().format('YYYY-MMM-DD HH:ss')
  })
  return data
}
