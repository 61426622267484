// Pathify
import { make } from 'vuex-pathify'

// Globals
// import { IN_BROWSER } from '@/util/globals'
// import axios from 'axios'

import categoryApi from '@/api/category'
import itemCategoryApi from '@/api/item-category'

const state = {
  receiptCategory: [],
  receiptCategoryLoad: 0,
  itemCategory: [],
  itemCategoryMeta: { },
  itemCategoryLoad: 0,
}

const mutations = make.mutations(state)

const actions = {
  setGetReceiptCategory: ({ commit }) => {
    return new Promise((resolve, reject) => {
      categoryApi.index().then(response => {
        const result = response.data.data
        result.push({ id: 0, name: 'All' })
        commit('receiptCategory', result)
        commit('receiptCategoryLoad', 1)
        resolve(true)
      }).catch(error => {
        reject(error)
      })
    })
  },
  setGetItemCategory: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      itemCategoryApi.index(data.categoryName, data.pageNumber).then(response => {
        commit('itemCategory', response.data.data)
        commit('itemCategoryMeta', response.data.meta)
        commit('itemCategoryLoad', 1)
      }).cache(error => {
        reject(error)
      })
    })
  },
}

const getters = {
  receiptCategory: state => {
    return state.receiptCategory
  },
  receiptCategoryLoad: state => {
    return state.receiptCategoryLoad
  },
  itemCategory: state => {
    return state.itemCategory
  },
  itemCategoryMeta: state => {
    return state.itemCategoryMeta
  },
  itemCategoryLoad: state => {
    return state.itemCategoryLoad
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
