import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index (data) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'equipmentTestingRecord',
      params: { key_word: data },
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'equipmentTestingRecord/' + id,
    })
  },
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'equipmentTestingRecord',
      data: {
        item_receipt_number: data.item_receipt_number,
        is_sos_order: data.is_sos_order,
        item_id: data.item.id,
        serial_number: data.serial_number,
        equipment_model_id: data.equipment_model_id,
        note: data.note,
        equipment_status_id: data.equipment_status_id,
      },
    })
  },
  update (id, data) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'equipmentTestingRecord/' + id,
      data: {
        item_receipt_number: data.item_receipt_number,
        is_sos_order: data.is_sos_order,
        item_id: data.item_id,
        serial_number: data.serial_number,
        images: data.images,
        equipment_model_id: data.equipment_model_id,
        note: data.note,
        equipment_status_id: data.equipment_status_id,
      },
    })
  },
  imageStore (formData) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'image/equipmentTestingRecord/cacheImage',
      data: formData,
    })
  },
  imageDestroy (equipmentId, imageInfo) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'image/equipmentTestingRecord/deleteImage',
      data: {
        equipment_testing_record_id: equipmentId,
        image_info: imageInfo,
      },
    })
  },
  destroy (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'equipmentTestingRecord/' + id,
    })
  },
  pageChange (page, data) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'equipmentTestingRecord?page=' + page,
      params: { key_word: data },
    })
  },
}
