<template>
  <v-row justify="center">
    <v-dialog
      v-model="testResultMigrateRequestDialog"
      max-width="1000"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="testResultMigrateRequestDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-card-title class="text-h5 text--primary font-weight-bold">
            Waiting Confirm Test Result
          </v-card-title>
          <v-card
            v-for="(testResult, index) in waitingConfirmResult"
            :key="index"
            class="ma-2"
          >
            <v-card-text class="text--primary">
              Move Info <br>
              TestID: {{ testResult.id }} From Receipt: {{ testResult.move_from_po_number }} -> To Receipt {{ testResult.move_to_po_number }}
              <v-divider />
              Item Info <br>
              SOS ID: {{ testResult.sos_item_id }} PN: {{ testResult.item_part_number }} Description: {{ testResult.item_description }}
              <v-divider />
              Test Result <br>
              Tested: {{ testResult.tested_quantity }} Failed: {{ testResult.failed_in_testing }} Charged By: {{ testResult.user.name }}
              <v-row>
                <v-col cols="6">
                  <v-select
                    v-model="testResult.to_receipt_item_id"
                    label="Select Same SOS ID"
                    :items="itemReceiptItems"
                    item-text="sos_item_id"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    v-model="testResult.to_status_id"
                    label="Select Item Status"
                    :items="taskStatus"
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions
              v-if="doneGroup.includes(testResult.id) === false"
            >
              <v-spacer></v-spacer>
              <v-btn
                mini-with="120"
                color="info"
                @click="updateWaitingConfirm(testResult)"
              >
                Update Test Result
              </v-btn>
              <v-btn
                mini-with="120"
                text
                color="red"
                @click="deleteWaitingConfirm(testResult)"
              >
                Delete Test Result
              </v-btn>
            </v-card-actions>
            <v-card-actions v-else>
              This test result has been updated
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
  import { get } from 'vuex-pathify'
  import store from '@/store'
  import testResultUpdateApi from '@/api/item-receipt-item-test'
  export default {
    name: 'TestResultMigrateRequest',
    props: { waitingConfirmResult: Array, itemReceiptItems: Array },
    data () {
      return {
        testResultMigrateRequestDialog: false,
        confirmTestResults: '',
        doneGroup: [],
      }
    },
    computed: {
      ...get('task', [
        'taskStatus',
      ]),
    },
    created () {
      store.set('task/get')
    },
    methods: {
      updateWaitingConfirm (data) {
        data.combined_type = 'combined'
        testResultUpdateApi.combined(data).then(response => {
          if (response.data.code === 200) this.doneGroup.push(data.id)
        }).catch(error => {
          console.log(error)
        })
      },
      deleteWaitingConfirm (data) {
        data.combined_type = 'remove'
        testResultUpdateApi.combined(data).then(response => {
          if (response.data.code === 200) this.doneGroup.push(data.id)
        }).catch(error => {
          console.log(error)
        })
      },
    },
  }
</script>

<style scoped>

</style>
