<template>
  <v-card>
    <v-card-title>
      Type Management
    </v-card-title>
    <v-card-text>
      Type Name: {{ editItemType.name }}
      <br>
      Request Check: {{ editItemType.request_check }}
      <br>
      Type Category: {{ editItemType.item_category ? editItemType.item_category.name : 'None' }}
    </v-card-text>
    <v-card-actions>
      <v-autocomplete
        v-model="selectItemTypeId"
        :items="itemTypeList"
        :loading="isLoadingItemTypeList"
        :search-input.sync="searchValue"
        dense
        item-text="full_name"
        item-value="id"
        clearable
        label="Chang Type"
      />
      <v-btn
        class="mx-2 mb-2 white--text"
        fab
        x-small
        color="blue-grey"
        :loading="ItemItemTypeUpdating"
        :disabled="ItemItemTypeUpdating"
        @click="changeItemType()"
      >
        <v-icon dark>
          mdi-checkbox-marked-circle
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import ItemTypeApi from '@/api/item-type'
  import ItemApi from '@/api/item'
  import store from '@/store'
  export default {
    name: 'TypeCreateEdit',
    props: { itemId: Number, itemTypeId: Number },
    data () {
      return {
        defaultItemType: {
          name: '',
          item_category_id: '',
          item_category: '',
          request_check: false,
          description: '',
        },
        editItemType: {
          name: '',
          item_category_id: '',
          item_category: '',
          request_check: false,
          description: '',
        },
        selectItemTypeId: '',
        searchValue: '1',
        isLoadingItemTypeList: false,
        itemTypeList: [],
        // item type change
        ItemItemTypeUpdating: false,
      }
    },
    watch: {
      searchValue (newVal) {
        if (newVal === null) return // eslint-disable-line
        if (this.itemTypeList.length > 0 && newVal.length > 2) return // eslint-disable-line
        if (this.isLoadingItemTypeList) return //eslint-disable-line
        this.isLoadingItemTypeList = true
        this.itemTypeListFilter(newVal)
      },
      itemTypeId (newVal) {
        this.loadItemType(newVal)
      },
    },
    mounted () {
      if (this.itemTypeId != null) this.loadItemType(this.itemTypeId)
    },
    methods: {
      loadItemType (id) {
        ItemTypeApi.show(id).then(response => {
          this.editItemType = response.data.data
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        })
      },
      itemTypeListFilter (keyword) {
        ItemTypeApi.quickSearch({ keyword: keyword }).then(response => {
          response.data.data.forEach((element) => {
            if (element.item_category) {
              element.full_name = element.item_category.name + '->' + element.name
            } else {
              element.full_name = 'none->' + element.name
            }
          })
          this.itemTypeList = response.data.data
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.isLoadingItemTypeList = false
        })
      },
      changeItemType () {
        this.ItemItemTypeUpdating = true
        ItemApi.update({
          id: this.itemId,
          item_type_id: this.selectItemTypeId,
        }).then(response => {
          this.$emit('update-itemInfo', response.data.data)
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.ItemItemTypeUpdating = false
        })
      },
    },
  }
</script>

<style scoped>

</style>
