import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default ({
  store (equipmentRecordId, data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'equipmentTestingPartsRecord',
      data: {
        equipment_testing_record_id: equipmentRecordId,
        item_id: data.item.id,
        quantity: data.quantity,
        serial_numbers: data.serial_numbers,
        status: data.status,
        note: data.note,
      },
    })
  },
  update (data) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'equipmentTestingPartsRecord/' + data.id,
      data: {
        equipment_testing_record_id: data.equipment_testing_record_id,
        item_id: data.item.id,
        quantity: data.quantity,
        serial_numbers: data.serial_numbers,
        status: data.status,
        note: data.note,
      },
    })
  },
  delete (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'equipmentTestingPartsRecord/' + id,
    })
  },
})
