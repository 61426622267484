import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'user',
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'user/' + id,
    })
  },
  update (data) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'user/' + data.id,
      data: data,
    })
  },
  loginUser () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'loginUser',
    })
  },
}

export function permission (data) {
  const token = JSON.parse(localStorage.getItem('userAuthData'))
  return axios({
    method: 'post',
    url: apiConfig.apiUri + 'user/canCheck',
    headers: { Authorization: `Bearer ${token.access_token}` },
    data: {
      permission: data,
    },
  })
}
