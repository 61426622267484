<template>
  <v-container>
    <v-dialog
      v-model="propertyEditDialog"
      persistent
      width="500"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Item Property {{ title }}</span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-row v-if="propertyDataLoading === false">
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="editItemProperty.name"
                  label="Name"
                  :disabled="disableNameInput"
                >
                </v-text-field>
              </v-col>
              <v-col
                v-if="propertyListInfo.length >0"
                cols="12"
                md="6"
              >
                <v-select
                  v-model="selectValue"
                  :items="propertyListInfo"
                  clearable
                  label="Select New Value"
                >
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="editItemProperty.value"
                  :label="valueTitle"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col class="text-md-h5 ma-2">
                Data Loading ...
              </v-col>
            </v-row>
          </v-form>
          <v-alert
            v-model="errorMessageAlert"
            dense
            dismissible
            :type="errorMessage.type"
          >
            {{ errorMessage.text }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="propertyId > 0"
            color="red"
            text
            @click="deleteProperty()"
          >
            DELETE
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="propertyEditDialog = false"
          >
            CLOSE
          </v-btn>
          <v-btn
            v-if="propertyId > 0"
            color="blue darken-1"
            text
            @click="updateProperty()"
          >
            UPDATE
          </v-btn>
          <v-btn
            v-else
            color="blue darken-1"
            text
            @click="createNewProperty(itemId)"
          >
            CREATE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import ItemPropertyApi from '@/api/item-property'
  import store from '@/store'
  export default {
    name: 'PropertyCreateEdit',
    props: {
      itemId: String,
    },
    computed: {
      title () {
        return this.propertyId > 0 ? 'Edit' : 'Create'
      },
      valueTitle () {
        return this.propertyId > 0 ? 'Current Value (Edit To Create New)' : 'Value'
      },
    },
    watch: {
      propertyEditDialog (newVal) {
        if (newVal === false) this.resetData()
      },
    },
    data () {
      return {
        propertyEditDialog: false,
        errorMessageAlert: false,
        errorMessage: {
          type: 'error',
          text: '',
        },
        propertyId: -1,
        propertyDataLoading: false,
        editItemProperty: {
          name: '',
          value: '',
        },
        defaultItemProperty: {
          name: '',
          value: '',
        },
        disableNameInput: false,
        propertyListInfo: [],
        selectValue: null,
      }
    },
    methods: {
      activeDialog (id, propertyList = null) {
        this.propertyEditDialog = true
        this.propertyId = id
        if (propertyList !== null) {
          this.editItemProperty.name = propertyList.name
          this.propertyListInfo = propertyList.list
          this.disableNameInput = true
        }
        if (id > 0) {
          this.propertyDataLoading = true
          ItemPropertyApi.show(id).then(response => {
            this.editItemProperty = response.data.data
            this.editItemProperty.otherValue.forEach((element) => this.propertyListInfo.push(element.value))
            this.propertyDataLoading = false
          })
        }
      },
      createNewProperty (itemId) {
        this.editItemProperty.item_id = itemId
        const propertyData = Object.assign({}, this.editItemProperty)
        if (this.disableNameInput === true && this.selectValue !== null) propertyData.value = this.selectValue
        ItemPropertyApi.store(propertyData).then(response => {
          this.propertyEditDialog = false
          store.set('message/storeMessage', {
            color: response.data.type,
            text: response.data.message,
            timeout: 5000,
          })
        }).catch(error => {
          this.errorMessage.text = error.response.data.message
          this.errorMessageAlert = true
        })
      },
      updateProperty () {
        const propertyData = Object.assign({}, this.editItemProperty)
        if (this.selectValue !== null) propertyData.value = this.selectValue
        ItemPropertyApi.update(propertyData, this.propertyId).then((response) => {
          this.propertyEditDialog = false
          store.set('message/storeMessage', {
            color: response.data.type,
            text: response.data.message,
            timeout: 5000,
          })
        }).catch(error => {
          this.errorMessage.text = error.response.data.message
          this.errorMessageAlert = true
        })
      },
      deleteProperty () {
        ItemPropertyApi.destroy(this.propertyId).then(response => {
          this.propertyEditDialog = false
          store.set('message/storeMessage', {
            color: response.data.type,
            text: response.data.message,
            timeout: 3500,
          })
        }).catch(error => {
          this.errorMessage.text = error.response.data.message
          this.errorMessageAlert = true
        })
      },
      resetData () {
        this.propertyId = -1
        this.errorMessageAlert = false
        this.errorMessage = Object.assign({}, { type: 'error', text: '' })
        this.editItemProperty = Object.assign({}, this.defaultItemProperty)
        this.disableNameInput = false
        this.propertyListInfo = []
        this.selectValue = null
        this.$emit('itemPropertyRefresh')
      },
    },
  }
</script>

<style scoped>

</style>
