import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index (categoryName, pageNumber) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemCategory?page=' + pageNumber,
      params: {
        category_name: categoryName,
      },
    })
  },
  quickSearch (keyword) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemCategoryQuickSearch',
      keyword,
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemCategory/' + id,
    })
  },
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemCategory',
      data: data,
    })
  },
  update (data, id) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'itemCategory/' + id,
      data: data,
    })
  },
  delete (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'itemCategory/' + id,
    })
  },
}
