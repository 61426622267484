<template>
  <v-main>
    <v-dialog
      v-model="manualShowDialog"
      max-width="800"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Document ID:  {{ manual.id }}
          <v-divider
            vertical
            class="mx-2"
          />
          Manual Name: {{ manual.name }}
        </v-card-title>
        <v-card-text class="ma-2">
          Last Update at: {{ manual.updated_at }}
          <br>
          Item Category: {{ manual.itemTypeCategory }}
          <br>
          Item Type: {{ manual.itemTypeName }}
          <br>
          Author: {{ manual.author }}
          <br>
          Key Words: {{ manual.specifyKeyword }}
        </v-card-text>
        <v-divider />
        <v-card-text class="ma-2">
          Description:
          <br>
          <div
            class="ProseMirror"
            v-html="manual.description"
          />
        </v-card-text>
        <v-divider />
        <v-card-text class="ma-2">
          More Detail or Url:
          <br>
          <v-row>
            <v-col
              cols="12"
              md="10"
            >
              {{ manual.documentUrl }}
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                text
                color="blue"
                @click="openExternalUrl(manual.documentUrl)"
              >
                View
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="manualShowDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
  export default {
    name: 'ItemManualView',
    props: {
      manual: Object,
    },
    data () {
      return {
        manualShowDialog: false,
      }
    },
    methods: {
      openExternalUrl (url) {
        window.open(url, '_blank')
      },
    },
  }
</script>

<style scoped>

</style>
