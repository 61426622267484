// Pathify
import { make } from 'vuex-pathify'
// import { IN_BROWSER } from '@/util/globals'
import axios from 'axios'
import itemReceiptApi from '@/api/item-receipt'
import { itemReceiptIndex } from '@/util/item-receipt-data-handle'
import moment from 'moment'
const state = {
  receipt: null,
  meta: {},
  receiptLoad: 0,
  filter: {
    categoryId: 0,
    taskStatusId: 0,
    key_word: '',
    order: 'recent_updated',
    date_from: 60,
  },
}
const mutations = make.mutations(state)

const actions = {
  setGet: ({ commit }, filter) => {
    commit('receiptLoad', 0)
    return new Promise((resolve, reject) => {
      itemReceiptApi.index(filter).then(response => {
        if (response.status === 200) {
          const receiptData = itemReceiptIndex(response.data.data)
          const receiptLinks = response.data.meta
          commit('receipt', receiptData)
          commit('meta', receiptLinks)
          commit('receiptLoad', 1)
        }
        resolve(true)
      }).catch(error => {
        commit('receiptLoad', 3)
        reject(error)
      })
    })
  },
  setChangePage: ({ commit, getters }, params) => {
    commit('receiptLoad', 0)
    return new Promise((resolve, reject) => {
      const link = getters.meta.path + '?page=' + params.page
      axios.get(link, { params: params.filter }).then(response => {
        const receiptData = itemReceiptIndex(response.data.data)
        const receiptLinks = response.data.meta
        commit('receipt', receiptData)
        commit('meta', receiptLinks)
        commit('receiptLoad', 1)
      }).catch(error => {
        commit('receiptLoad', 3)
        reject(error)
      })
    })
  },
  /*
  setFilter: ({ commit }, filter) => {
    commit('filter', filter)
  },
   */
  setResetFilter: ({ commit }, defaultFilter) => {
    const defaultFilterObject = Object.assign({}, defaultFilter)
    commit('filter', defaultFilterObject)
  },
}

const getters = {
  receipt: state => {
    return state.receipt
  },
  meta: state => {
    return state.meta
  },
  receiptLoad: state => {
    return state.receiptLoad
  },
  filter: state => {
    return state.filter
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
