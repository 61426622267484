<template>
  <v-container>
    <v-row>
      <v-card-title class="text-lg-h5">
        Management Upload Picture
      </v-card-title>
      <v-card-text>
        <v-col
          v-for="(imageInfo, key) in recordItem.images"
          :key="key"
          cols="4"
        >
          <v-card
            v-if="imageInfo.location === 'cloud_google'"
          >
            <v-img
              :src="imageInfo.file_path"
            />
            <v-card-actions>
              <v-icon
                dense
                color="green darken-1"
              >
                mdi-cloud-check-outline
              </v-icon>
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="imageDelete(imageInfo)"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-card-text>
    </v-row>
    <v-row>
      <v-card-title class="text-lg-h5">
        Pictures Ready Upload
      </v-card-title>
      <v-card-text>
        <v-col
          v-for="(imageInfo, key) in recordItem.images"
          :key="key"
          cols="4"
        >
          <v-card v-if="imageInfo.location === 'local_cache'">
            <v-img
              :src="imageInfo.file_path"
            />
            <v-card-actions>
              Ready Upload
              <v-spacer></v-spacer>
              <v-btn
                icon
                small
                @click="imageDelete(imageInfo)"
              >
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-card-text>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <v-file-input
          v-model="multipleImageFiles"
          accept="image/png, image/jpeg, image/bmp"
          multiple
          label="File input"
          @change="imageUpload"
        />
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="mt-2"
      >
        <v-btn
          block
          @click="uploadToCloud()"
        >
          Confirm Upload Picture
        </v-btn>
      </v-col>
      <v-col cols="12">
        <h5>
          * Click <i>Confirm Upload Picture</i> to save the picture
          <br>
          * You can close windows, if you don't need upload picture
        </h5>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import equipmentTestRecordApi from '@/api/equipment-testing-record'
  export default {
    name: 'ImageManagement',
    props: {
      recordItem: {
        type: Object,
      },
    },
    /*
    created () {
      console.log(JSON.parse(this.recordItem.images))
    },
   */
    data () {
      return {
        imageUrl: '',
        imagesArray: [],
        multipleImageFiles: [],
      }
    },
    methods: {
      async imageUpload () {
        await this.multipleImageFiles.forEach(element => {
          const image = new FormData()
          image.append('equipment_picture', element, element.name)
          image.append('record_id', this.recordItem.id)
          image.append('file_name_header', 'record_id_' + this.recordItem.id + '_' + this.recordItem.item.part_number)
          equipmentTestRecordApi.imageStore(image).then(response => {
            if (response.data.code === 200) {
              // this.imagesArray.push(response.data.data)
              this.$emit('updated-image', { method: 'add', imageInfo: response.data.data })
            }
          }).catch(error => {
            console.log(error)
          })
        })
        /*
        if (this.recordItem.images > 0) {
          await this.recordItem.images.forEach((element) => this.imagesArray.push(element))
        }
        await this.$emit('saved', this.imagesArray)
         */
      },
      uploadToCloud () {
        this.$emit('updated-image', { method: 'cloud', imageInfo: null })
      },

      imageDelete (imageInfo) {
        if (imageInfo.location === 'cloud_google') {
          equipmentTestRecordApi.imageDestroy(this.recordItem.id, imageInfo).then(response => {
            if (response.data.code === 200) {
              this.$emit('updated-image', { method: 'delete', imageInfo: imageInfo })
            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          this.$emit('updated-image', { method: 'delete', imageInfo: imageInfo })
        }
      },
    },
  }
</script>

<style scoped>

</style>
