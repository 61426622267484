<template>
  <div class="text-center">
    <v-snackbar
      v-if="message.color.length>0"
      v-model="activeMessage"
      :timeout="message.timeout"
      :color="message.color"
      top
    >
      {{ message.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="activeMessage=false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  import { get } from 'vuex-pathify'
  import store from '@/store'
  export default {
    name: 'SnackBar',
    data () {
      return {
        activeMessage: false,
      }
    },
    watch: {
      activeMessage (val, oldVal) {
        if (oldVal === true && val === false) {
          store.set('message/storeMessage', {
            color: '',
            text: '',
            timeout: 3000,
          })
        }
      },
      'message.timeout': {
        handler: function (val) {
          if (val !== 3000) this.activeMessage = true
        },
        deep: true,
      },
    },
    computed: {
      ...get('message', ['message']),
    },
    methods: {
      activeStatusInfo (status = true) {
        this.activeMessage = status
      },
    },
  }
</script>

<style scoped>

</style>
