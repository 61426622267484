<template>
  <v-dialog
    v-model="configurationListDetailDialog"
    transition="dialog-bottom-transition"
    max-width="800"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
      >
        Configuration List ID: {{ configurationListId }}, Model: {{ modelFullName }}
      </v-toolbar>
      <v-card-text>
        <v-col
          cols="12"
        >
          <v-data-table
            :headers="configurationPartHeader"
            :items="showData.content"
            dense
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Configuration Part</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
        <v-col
          cols="12"
        >
          <v-data-table
            :headers="equipmentStatusHeader"
            :items="showData.equipment_record_ids"
            dense
          >
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-toolbar-title>Equipment Status</v-toolbar-title>
              </v-toolbar>
            </template>
          </v-data-table>
        </v-col>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          text
          @click="configurationListDetailDialog = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'ListDetailShow',
    props: { showData: Object },
    data () {
      return {
        configurationListDetailDialog: false,
        configurationPartHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'item_id',
          },
          { text: 'Part', value: 'info.part_number' },
          { text: 'Description', value: 'info.description' },
          { text: 'Type', value: 'info.item_type.name' },
          { text: 'Bin', value: 'info.bin_number' },
          { text: 'Qty', value: 'quantity' },
        ],
        equipmentStatusHeader: [
          {
            text: 'ID',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'PN', value: 'item.part_number' },
          { text: 'Type', value: 'item.item_type.name' },
          { text: 'BIN', value: 'item.bin_number' },
          { text: 'SN', value: 'serial_number' },
          { text: 'Status', value: 'equipment_status.name' },
        ],
      }
    },
    computed: {
      configurationListId () {
        return this.showData.id ? this.showData.id : 'Not Ready'
      },
      modelFullName () {
        let result = 'Not Ready'
        if (this.showData.fullModelName) {
          result = this.showData.fullModelName
        }
        return result
      },
    },
  }
</script>

<style scoped>

</style>
