import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'equipmentModel',
    })
  },
  filter (data) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'model/filter',
      params: {
        type: data.type,
        brand: data.brand,
        generation: data.generation,
      },
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'equipmentModel/' + id,
    })
  },
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'equipmentModel',
      data: data,
    })
  },
  update (data, id) {
    return axios({
      method: 'patch',
      url: apiConfig.apiUri + 'equipmentModel/' + id,
      data: data,
    })
  },
  destroy (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'equipmentModel/' + id,
    })
  },
}
