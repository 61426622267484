<template>
  <v-card
    class="mx-5"
  >
    <v-card-title>
      <v-badge
        color="blue"
        :content="totalReceipt"
      >
        Real Time Receipt Done In Testing
      </v-badge>
      <v-spacer></v-spacer>
      <v-icon
        small
        color="blue"
        title="Real Time Verified"
      >
        mdi-check-decagram
      </v-icon>
      <v-divider
        vertical
        class="mx-4"
      >
      </v-divider>
      <v-btn
        v-if="showDoneTestingDashboard"
        x-small
        text
        @click="$store.set('app/showDoneTestingDashboard',false)"
      >
        Hidden Board
      </v-btn>
      <v-btn
        v-else
        x-small
        text
        @click="$store.set('app/showDoneTestingDashboard',true)"
      >
        Show Board
      </v-btn>
    </v-card-title>
    <v-card-text>
      * The receipt's location in default will be auto remove within 24hrs
    </v-card-text>
    <v-data-table
      v-if="showDoneTestingDashboard"
      :headers="receiptStatusBoardHeader"
      :items="receiptStatusBoardData"
      :items-per-page="5"
      dense
      :search="search"
    >
      <template v-slot:top>
        <v-row>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="search"
              label="Search Keyword"
              dense
              class="mx-4"
              clearable
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="3"
          >
            <v-select
              v-model="marked"
              :items="[{name: 'All', value: 'all'}, {name: 'Marked', value: true},{name: 'unMarked', value: false}]"
              item-text="name"
              item-value="value"
              type="boolean"
              label="Filter By Marked"
              dense
              class="mx-4"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.detail="{ item }">
        <v-icon
          title="View Detail"
          small
          class="mr-2"
          @click="showReceipt(item)"
        >
          mdi-text-box-search
        </v-icon>
      </template>
      <template v-slot:item.marked="{ item }">
        <v-icon
          :color="getMarkColor(item.marked)"
          title="Mark or unMark it"
          small
          class="mr-2"
          @click="changeMark(item)"
        >
          mdi-star-plus
        </v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import ItemReceiptApi from '../../api/item-receipt'
  import store from '@/store'
  import { get } from 'vuex-pathify'
  export default {
    name: 'RealTimeTestingUpdateBoard',
    mounted () {
      window.Echo.channel('receipt-status-updated').listen('.newDoneInTestingReceipt', (response) => {
        if (!response.data.data.request_user_id || response.data.data.request_user_id !== this.userId) {
          store.set('message/storeMessage', {
            color: 'info',
            text: response.message,
            timeout: 3500,
          })
        }
        if (response.data.data.request_update) return this.updateNotificationTable()
        this.updateNotificationItem(response.data.data)
      })
      if (this.receiptStatusBoardData.length === 0) this.updateNotificationTable()
    },
    computed: {
      ...get('app', ['showDoneTestingDashboard']),
      ...get('user', ['userId']),
    },
    data () {
      return {
        receiptStatusBoardHeader: [
          {
            text: 'Receipt #',
            value: 'po_number',
            align: 'start',
          },
          {
            text: 'Mark',
            value: 'marked',
            filter: value => {
              if (this.marked === 'all') return true
              return value === this.marked
            },
          },
          { text: 'Type', value: 'category' },
          { text: 'Status', value: 'task_status' },
          { text: 'Updated At', value: 'updated_at' },
          { text: 'Time Zone', value: 'time_zone' },
          { text: 'Detail', value: 'detail' },
        ],
        receiptStatusBoardData: [],
        search: '',
        marked: 'all',
        totalReceipt: 0,
      }
    },
    methods: {
      updateNotificationTable () {
        ItemReceiptApi.syncDoneInTesting().then(response => {
          this.receiptStatusBoardData = response.data.data
          this.totalReceipt = this.receiptStatusBoardData.length
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
        })
      },
      updateNotificationItem (data) {
        const key = this.receiptStatusBoardData.findIndex(obj => obj.id === data.receipt.id)
        if (key > -1) {
          this.receiptStatusBoardData[key].marked = data.receipt.marked
        } else {
          this.receiptStatusBoardData.push(data.receipt)
        }
      },
      getMarkColor (marked) {
        return marked ? 'red' : 'grey'
      },
      changeMark (item) {
        ItemReceiptApi.changeMarkInDashboard({
          item_receipt_id: item.id,
          marked: !item.marked,
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
        })
      },
      showReceipt (item) {
        this.$router.push({ name: 'Receipt Show', params: { id: item.id } }).catch(() => {})
      },
    },
  }
</script>

<style scoped>

</style>
