import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index (data, page) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'equipmentConfigurationList?page=' + page,
      params: {
        generation: data.generation,
        brand: data.brand,
        status: data.status,
      },
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'equipmentConfigurationList/' + id,
    })
  },
  checkConfiguration (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'configurationCheck',
      data: {
        type: data.type,
        equipment_id: data.equipment_id,
      },
    })
  },
  recheckConfiguration () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'currentEquipmentsConfigurationCheck',
    })
  },
  exportListingConfigurationList (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'exportConfigurationListForListing',
      data,
    })
  },
}
