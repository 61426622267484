<template>
  <v-card-text>
    <v-dialog
      v-model="imageInsertDialog"
      width="500"
    >
      <v-card>
        <v-card-actions
          class="mt-1"
        >
          <v-text-field
            v-model="image_url"
            label="Copy image link in there"
            dense
          />
          <v-btn
            class="ml-1"
            text
            @click="imageInsert()"
          >
            Insert
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="copyToHtmlDialog"
      scrollable
      width="500"
    >
      <v-card>
        <v-card-title>If Clipboard Not Working Correctly, Copy Content From Below</v-card-title>
        <v-divider />
        <v-card-text
          style="height: 300px; color: black;"
          class="mt-2"
        >
          {{ htmlContent }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card-actions
          v-if="richEditor"
          class="TipTapEditorBar"
        >
          <button
            :class="{ 'is-active': richEditor.isActive('bold') }"
            @click="richEditor.chain().focus().toggleBold().run()"
          >
            <v-icon small>
              mdi-format-bold
            </v-icon>
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('italic') }"
            @click="richEditor.chain().focus().toggleItalic().run()"
          >
            <v-icon small>
              mdi-format-italic
            </v-icon>
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('code') }"
            @click="richEditor.chain().focus().toggleCode().run()"
          >
            <v-icon small>
              mdi-xml
            </v-icon>
          </button>
          <button @click="richEditor.chain().focus().unsetAllMarks().run()">
            <v-icon small>
              mdi-eraser-variant
            </v-icon>
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('paragraph') }"
            @click="richEditor.chain().focus().setParagraph().run()"
          >
            <v-icon small>
              mdi-format-paragraph
            </v-icon>
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('heading', { level: 1 }) }"
            @click="richEditor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            h1
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('heading', { level: 2 }) }"
            @click="richEditor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            h2
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('heading', { level: 3 }) }"
            @click="richEditor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            h3
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('heading', { level: 4 }) }"
            @click="richEditor.chain().focus().toggleHeading({ level: 4 }).run()"
          >
            h4
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('heading', { level: 5 }) }"
            @click="richEditor.chain().focus().toggleHeading({ level: 5 }).run()"
          >
            h5
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('heading', { level: 6 }) }"
            @click="richEditor.chain().focus().toggleHeading({ level: 6 }).run()"
          >
            h6
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('bulletList') }"
            @click="richEditor.chain().focus().toggleBulletList().run()"
          >
            <v-icon small>
              mdi-format-list-bulleted-square
            </v-icon>
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('orderedList') }"
            @click="richEditor.chain().focus().toggleOrderedList().run()"
          >
            <v-icon small>
              mdi-format-list-numbered
            </v-icon>
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('codeBlock') }"
            @click="richEditor.chain().focus().toggleCodeBlock().run()"
          >
            <v-icon small>
              mdi-file-code
            </v-icon>
          </button>
          <button
            :class="{ 'is-active': richEditor.isActive('blockquote') }"
            @click="richEditor.chain().focus().toggleBlockquote().run()"
          >
            <v-icon small>
              mdi-format-horizontal-align-right
            </v-icon>
          </button>
          <button @click="richEditor.chain().focus().setHorizontalRule().run()">
            <v-icon small>
              mdi-arrow-split-horizontal
            </v-icon>
          </button>
          <button @click="richEditor.chain().focus().setHardBreak().run()">
            <v-icon small>
              mdi-format-page-break
            </v-icon>
          </button>
          <button @click="imageInsertDialog = true">
            <v-icon small>
              mdi-file-image-plus
            </v-icon>
          </button>
          <v-spacer />
          <button @click="richEditor.chain().focus().undo().run()">
            <v-icon small>
              mdi-undo-variant
            </v-icon>
          </button>
          <button @click="richEditor.chain().focus().redo().run()">
            <v-icon small>
              mdi-redo-variant
            </v-icon>
          </button>
          <v-btn
            x-small
            text
            @click="copyContentToHtml()"
          >
            Copy as Html
          </v-btn>
        </v-card-actions>
      </v-col>
      <v-col
        cols="12"
      >
        <editor-content
          class="ProseMirror"
          :editor="richEditor"
        />
        <v-card-actions>
          <v-switch
            dense
            v-model="editable"
            :label="`Editable: ${editable.toString()}`"
          ></v-switch>
          <v-btn
            class="mx-2"
            style="color: #EEEEEE"
            small
            color="blue"
            @click="copyPropertyToContent()"
          >
            Load Properties
          </v-btn>
          <v-spacer />
          <v-btn
            small
            text
            @click="loadLongDescription()"
          >
            Reset
          </v-btn>
          <v-btn
            :disabled="updateProcessing"
            small
            text
            color="primary"
            @click="longDescriptionUpdate()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
  import ItemApi from '@/api/item'
  import ItemPropertyApi from '@/api/item-property'
  import { Editor, EditorContent } from '@tiptap/vue-2'
  import StarterKit from '@tiptap/starter-kit'
  import Image from '@tiptap/extension-image'
  import store from '@/store'
  export default {
    name: 'LongDescriptionEdit',
    components: { EditorContent },
    props: { item: Object },
    data () {
      return {
        editable: false,
        imageInsertDialog: false,
        longDescriptionEditor: null,
        richEditor: null,
        image_url: '',
        copyToHtmlDialog: false,
        htmlContent: '',
        updateProcessing: false,
      }
    },
    mounted () {
      this.initContent()
    },
    beforeDestroy () {
      this.richEditor.destroy()
    },
    watch: {
      editable (newVal) {
        this.richEditor.setEditable(newVal)
      },
    },
    methods: {
      loadLongDescription () {
        ItemApi.show(this.item.id).then(response => {
          this.richEditor.commands.setContent(response.data.data.long_description)
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.editable = false
        })
      },
      initContent () {
        let content = '<p>Thanks for tiptap with Vue.js. 🎉</p>'
        if (this.item.long_description != null) content = this.item.long_description
        this.richEditor = new Editor({
          content: content,
          editable: this.editable,
          extensions: [
            StarterKit,
            Image,
          ],
        })
      },
      imageInsert () {
        this.richEditor.chain().focus().setImage({ src: this.image_url }).run()
        this.imageInsertDialog = false
      },
      copyContentToHtml () {
        this.htmlContent = this.richEditor.getHTML()
        navigator.clipboard.writeText(this.htmlContent)
        this.copyToHtmlDialog = true
      },
      copyPropertyToContent () {
        let longDescription = this.richEditor.getHTML()
        ItemPropertyApi.index(this.item.id).then(response => {
          let propertyDescription = ''
          response.data.data.forEach((element) => {
            propertyDescription = propertyDescription + element.name + ': ' + element.value + '<br>'
          })
          longDescription = propertyDescription + longDescription
          this.richEditor.commands.setContent(longDescription)
          this.editable = true
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        })
      },
      longDescriptionUpdate () {
        this.updateProcessing = true
        const itemInfo = Object.assign({}, this.item)
        itemInfo.long_description = this.richEditor.getHTML()
        ItemApi.update(itemInfo).then(response => {
          this.$emit('update-itemInfo', response.data.data)
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.editable = false
          this.updateProcessing = false
        })
      },
    },
  }
</script>

<style scoped>

</style>
