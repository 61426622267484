import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
    index () {
      return axios({
        method: 'get',
        url: apiConfig.apiUri + 'itemReceiptItemTest',
      })
    },
    show (id) {
      return axios({
        method: 'get',
        url: apiConfig.apiUri + 'itemReceiptItemTest' + id,
      })
    },
    store (data) {
        return axios({
            method: 'post',
            url: apiConfig.apiUri + 'itemReceiptItemTest',
            data: {
              item_receipt_item_id: data.item_receipt_item_id,
              tested_quantity: data.tested_quantity,
              failed_in_testing: data.failed_in_testing,
              task_status_id: data.task_status_id,
              test_server: data.test_server,
            },
        })
    },
  delete (id) {
      return axios({
        method: 'delete',
        url: apiConfig.apiUri + 'itemReceiptItemTest/' + id,
      })
  },
  combined (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemReceiptItemTest/combined',
      data,
    })
  },
}
