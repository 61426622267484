import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index (itemId) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemProperty',
      params: {
        item_id: itemId,
      },
    })
  },
  sameCategoryNoOwn (itemId) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemProperty/sameCategoryItemProperty/' + itemId,
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemProperty/' + id,
    })
  },
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemProperty',
      data,
    })
  },
  update (data, id) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'itemProperty/' + id,
      data,
    })
  },
  destroy (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'itemProperty/' + id,
    })
  },
}
