<template>
  <v-card>
    <v-card-title>
      Category Management
    </v-card-title>
    <v-card-text>
      Category Name: {{ editItemCategory.name }}
      <br>
      Directory: {{ editItemCategory.is_directory }}
      <br>
      Path: {{ editItemCategory.full_name }}
    </v-card-text>
    <v-card-actions>
      <v-autocomplete
        v-model="selectCategoryId"
        :items="itemCategoryList"
        :loading="isLoadingItemCategoryList"
        :search-input.sync="searchValue"
        dense
        item-text="full_name"
        item-value="id"
        clearable
        label="Chang Category"
      />
      <v-btn
        class="mx-2 mb-2 white--text"
        fab
        x-small
        color="blue-grey"
        :loading="categoryForItemUpdating"
        :disabled="categoryForItemUpdating"
        @click="changeItemCategory()"
      >
        <v-icon dark>
          mdi-checkbox-marked-circle
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import ItemCategoryApi from '@/api/item-category'
  import ItemApi from '@/api/item'
  import store from '@/store'
  export default {
    name: 'CategoryCreateEdit',
    props: { itemCategoryId: Number, itemId: Number },
    data () {
      return {
        selectCategoryId: '',
        defaultItemCategory: {
          name: '',
          is_directory: true,
          path: '',
          level: '',
          parent_id: null,
          parent_category: '',
          full_name: '',
        },
        editItemCategory: {
          name: '',
          is_directory: true,
          path: '',
          level: '',
          parent_id: null,
          parent_category: '',
          full_name: '',
        },
        searchValue: '',
        isLoadingItemCategoryList: false,
        itemCategoryList: [],
        // item category change
        categoryForItemUpdating: false,
      }
    },
    watch: {
      searchValue (newVal) {
        if (newVal === null) return // eslint-disable-line
        if (this.itemCategoryList.length > 0 && newVal.length > 2) return // eslint-disable-line
        if (this.isLoadingItemCategoryList) return // eslint-disable-line
        this.isLoadingItemCategoryList = true
        this.itemCategoryListFilter(newVal)
      },
      itemCategoryId (newVal) {
        this.getItemCategoryInfo(newVal)
      },
    },
    mounted () {
      if (this.itemCategoryId != null) this.getItemCategoryInfo(this.itemCategoryId)
    },
    methods: {
      getItemCategoryInfo (id) {
        ItemCategoryApi.show(id).then(response => {
          this.editItemCategory = response.data.data
        })
      },
      itemCategoryListFilter (keyword) {
        ItemCategoryApi.quickSearch(keyword).then(response => {
          this.itemCategoryList = response.data.data
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.isLoadingItemCategoryList = false
        })
      },
      changeItemCategory () {
        this.categoryForItemUpdating = true
        ItemApi.update({
          id: this.itemId,
          itemCategoryId: this.selectCategoryId,
        }).then(response => {
          this.$emit('update-itemInfo', response.data.data)
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.categoryForItemUpdating = false
        })
      },
    },
  }
</script>

<style scoped>

</style>
