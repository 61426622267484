import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'ItemType',
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'ItemType/' + id,
    })
  },
  quickSearch (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemTypeQuickSearch',
      data,
    })
  },
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'ItemType',
      data: {
        name: data.name,
        item_category_id: data.item_category_id,
        request_check: data.request_check,
        description: data.description,
      },
    })
  },
  update (data) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'ItemType/' + data.id,
      data: {
        name: data.name,
        item_category_id: data.item_category_id,
        request_check: data.request_check,
        description: data.description,
      },
    })
  },
  delete (id) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'ItemType/' + id,
    })
  },
  typeAndCategory () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemTypeAndCategory',
    })
  },
}
