import { make } from 'vuex-pathify'

const state = {
  selectedExportList: [],
}

const mutations = make.mutations(state)

const actions = {
  setSelectedExportListData: ({ commit }, data) => {
    commit('selectedExportList', data)
  },
}
const getters = {
  selectedExportList: state => {
    return state.selectedExportList
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
