<template>
  <v-dialog
    v-model="createRecordDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn
          text
          @click="createAnother"
        >
          Create New One
        </v-btn>
      </v-toolbar>
      <v-container
        fluid
        tag="section"
      >
        <v-card>
          <div>
            <v-tabs
              v-model="process"
              class="ma-2"
            >
              <v-tab @click="process = 0">
                Edit Equipment
              </v-tab>
              <v-tab @click="process = 1">
                Edit Parts
              </v-tab>
              <v-tab @click="process = 2">
                EDIT Picture
              </v-tab>
              <v-divider vertical />
              <v-tab @click="process = 3">
                Create New Item
              </v-tab>
            </v-tabs>
          </div>
          <div v-if="process === 0">
            <v-form class="ma-2">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="editEquipment.item"
                    label="Select Equipment Part Number *"
                    :items="itemsQuery"
                    :loading="isLoadingItemsQuery"
                    :search-input.sync="itemSearch"
                    placeholder="Select..."
                    item-text="showText"
                    item-value="id"
                    hide-selected
                    return-object
                  />
                </v-col>
                <v-col cols="6">
                  Current Select Equipment: <br>
                  {{ editEquipment.item.part_number }} BIN: {{ editEquipment.item.bin_number }} <br>
                  {{ editEquipment.item.description }}
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editEquipment.item_receipt_number"
                    label="Item Receipt Number"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  class="mt-2"
                >
                  <v-checkbox
                    v-model="editEquipment.is_sos_order"
                    label="Check Box If it is SOS Order"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editEquipment.serial_number"
                    label="Equipment Serial Number *"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="editEquipment.equipment_status_id"
                    label="Status *"
                    :items="equipmentStatus"
                    item-value="id"
                    item-text="name"
                  />
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-select
                    v-model="editModelFilter.brand"
                    label="Equipment Brand (Filter Options)"
                    :items="modelBrands"
                  />
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-select
                    v-model="editModelFilter.generation"
                    label="Equipment Generation (Filter Options)"
                    :items="modelGenerations"
                    required
                  />
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-select
                    v-model="editEquipment.equipment_model_id"
                    label="Equipment model *"
                    :items="modelFilterResult"
                    item-value="id"
                    item-text="name"
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="editEquipment.note"
                    label="Comment:"
                    rows="3"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-btn
                block
                @click="createdRecord()"
              >
                {{ equipmentButtonDisplay }}
              </v-btn>
            </v-form>
          </div>
          <div
            v-if="process === 1"
          >
            <v-form class="ma-2">
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-autocomplete
                    v-model="editEquipmentPart.item"
                    label="Select Part Number for Equipment's Part *"
                    :items="itemsQuery"
                    :loading="isLoadingItemsQuery"
                    :search-input.sync="itemSearch"
                    placeholder="Select..."
                    item-text="showText"
                    item-value="id"
                    hide-selected
                    return-object
                  />
                </v-col>
                <v-col cols="6">
                  Current Select Part: <br>
                  {{ editEquipmentPart.item.part_number }} BIN: {{ editEquipmentPart.item.bin_number }}<br>
                  {{ editEquipmentPart.item.description }}
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editEquipmentPart.quantity"
                    label="Quantity"
                    type="number"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-select
                    v-model="editEquipmentPart.status"
                    label="Status"
                    :items="equipmentPartStatus"
                    required
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="editEquipmentPart.serial_numbers"
                    label="Serial Numbers, use ',' to separate each SN"
                    rows="2"
                    clearable
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="editEquipmentPart.note"
                    label="Comment:"
                    rows="2"
                    clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-btn
                    block
                    @click="resetPart"
                  >
                    Reset Form
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    block
                    @click="loadRecord(editEquipmentId)"
                  >
                    Reset Table
                  </v-btn>
                </v-col>
                <v-col cols="4">
                  <v-btn
                    block
                    :disabled="RecordCreated"
                    @click="savePart()"
                  >
                    {{ partSubmitCategory }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="ma-2">
              <v-col cols="12">
                <v-data-table
                  :headers="equipmentPartsHeader"
                  :items="equipmentParts"
                  item-key="item.part_number"
                  dense
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editPart(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deletePart(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
              <v-btn
                block
                :disabled="RecordCreated"
                @click="uploadParts()"
              >
                Submit
              </v-btn>
            </v-row>
          </div>
          <div v-if="process === 2">
            <image-management
              :record-item="editEquipment"
              @updated-image="updateImagesData"
            />
          </div>
          <div v-if="process === 3">
            <item-create />
          </div>
        </v-card>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import equipmentRecordApi from '@/api/equipment-testing-record'
  import equipmentRecordPartApi from '@/api/equipment-testing-part-record'
  import equipmentStatusApi from '@/api/equipment-status'
  import ImageManagement from '@/components/equipment/ImageManagement'
  import { get } from 'vuex-pathify'
  import itemApi from '@/api/item'
  import ItemCreate from '@/components/item/create'
  import modelApi from '@/api/model'
  import configListApi from '@/api/equipment-configuration-list'
  import store from '@/store'
  export default {
    name: 'TestingRecord',
    components: { ImageManagement, ItemCreate },
    data () {
      return {
        createRecordDialog: false,
        process: 0,
        stopContinueItemSearch: false,
        itemSearch: '',
        itemSearchResult: [],
        isLoadingItemsQuery: false,
        // itemsQuery: [],
        equipmentPartDialog: false,
        equipmentParts: [],
        equipmentStatus: [],
        editEquipmentId: -1,
        defaultEquipment: {
          id: '',
          item_receipt_number: '',
          is_sos_order: false,
          item: '',
          serial_number: '',
          equipment_model_id: '',
          note: '',
          images: [],
          equipment_status_id: 1,
          equipment_configuration_list_id: '',
        },
        editEquipment: {
          id: '',
          item_receipt_number: '',
          is_sos_order: false,
          item: '',
          serial_number: '',
          equipment_model_id: '',
          note: '',
          images: [],
          equipment_status_id: 1,
          equipment_configuration_list_id: '',
        },
        equipmentPartsHeader: [
          {
            text: 'Item',
            align: 'start',
            sortable: false,
            value: 'item.part_number',
          },
          { text: 'Qty', value: 'quantity' },
          { text: 'Type', value: 'item.item_type.name' },
          { text: 'SN', value: 'serial_numbers' },
          { text: 'Status', value: 'status' },
          { text: 'Note', value: 'note' },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        editEquipmentPartIndex: -1,
        defaultEquipmentPart: {
          id: -1,
          item: '',
          quantity: '',
          serial_numbers: '',
          note: '',
          status: 'original',
        },
        editEquipmentPart: {
          id: -1,
          item: '',
          quantity: '',
          serial_numbers: '',
          note: '',
          status: 'original',
        },
        requestRemovePartArray: [],
        equipmentPartStatus: ['original', 'added', 'removed'],
        editModelFilter: {
          type: '',
          brand: '',
          generation: '',
        },
        defaultModelFilter: {
          type: '',
          brand: '',
          generation: '',
        },
        modelBrands: [],
        modelGenerations: [],
        modelFilterResult: [],
      }
    },
    computed: {
      ...get('app/', ['itemTypes']),
      itemsQuery () {
        return this.itemSearchResult
      },
      RecordCreated () {
        return !(this.editEquipmentId > 0)
      },
      equipmentButtonDisplay () {
        return this.editEquipmentId > -1 ? 'Update & Next' : 'Create & Next'
      },
      partSubmitCategory () {
        return this.editEquipmentPartIndex > -1 ? 'Update Part' : 'Add Part to List'
      },
    },
    watch: {
      itemSearch (newVal) {
        // if (this.itemSearchResult.length > 0) return
        // if (this.stopContinueItemSearch === true) return
        if (newVal && newVal.length >= 3) {
          this.isLoadingItemsQuery = true
          itemApi.quickSearch(this.itemSearch).then(response => {
            if (response.data.data.length > 0) {
              const result = response.data.data
              console.log(result)
              result.forEach((element) => {
                element.showText = 'PN: ' + element.part_number + ' BIN:' + element.bin_number
              })
              this.itemSearchResult = result
            }
          }).finally(() => { this.isLoadingItemsQuery = false })
        }
      },
      createRecordDialog (newVal) {
        if (newVal === true) {
          this.loadModelBrand()
          this.loadEquipmentStatus()
        }
      },
      'editModelFilter.brand': {
        handler: function (newVal) {
          if (newVal) this.loadModelGeneration()
        },
        deep: true,
      },
      'editModelFilter.generation': {
        handler: function (newVal) {
          if (newVal) this.loadFilteredModel()
        },
        deep: true,
      },
    },
    methods: {
      loadModelBrand () {
        this.editModelFilter = {
          type: 'brand',
          brand: null,
          generation: null,
        }
        modelApi.filter(this.editModelFilter).then(response => {
          if (response.data.code === 200) this.modelBrands = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      loadModelGeneration () {
        this.editModelFilter.type = 'generation'
        modelApi.filter(this.editModelFilter).then(response => {
          if (response.data.code === 200) this.modelGenerations = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      loadFilteredModel () {
        this.editModelFilter.type = 'model'
        modelApi.filter(this.editModelFilter).then(response => {
          if (response.data.code === 200) this.modelFilterResult = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      loadEquipmentStatus () {
        equipmentStatusApi.index().then(response => {
          this.equipmentStatus = response.data.data
        })
      },
      createdRecord () {
        if (this.editEquipmentId > -1) {
          this.updateRecord().then(response => {
            if (response.data.code === 200) this.process = 1
          })
        } else {
          this.saveRecord().then(response => {
            if (response === true) this.process = 1
          })
        }
        this.process = 1
      },
      loadRecord (id) {
        equipmentRecordApi.show(id).then(response => {
          this.editEquipmentId = response.data.data.id
          this.editEquipment = Object.assign({}, response.data.data)
          this.equipmentParts = this.editEquipment.equipment_part
          this.equipmentParts.forEach((element) => {
            if (Array.isArray(element.serial_numbers)) {
              element.serial_numbers = element.serial_numbers.toString()
            }
          })
          this.editEquipment.equipment_model_id = this.editEquipment.model.id
          this.modelFilterResult.push(this.editEquipment.model)
          console.log(this.equipmentParts)
        }).finally(() => {
          this.createRecordDialog = true
        })
      },
      saveRecord () {
        return new Promise((resolve, reject) => {
          equipmentRecordApi.store(this.editEquipment).then(response => {
            if (response.data.code !== 200) {
              reject(response.data.message)
            } else {
              this.editEquipmentId = response.data.data.id
              this.editEquipment = response.data.data
              resolve(true)
            }
          }).catch(error => {
            reject(error)
          })
        })
      },
      updateRecord () {
        return new Promise((resolve, reject) => {
          equipmentRecordApi.update(this.editEquipmentId, this.editEquipment).then(response => {
            if (response.data.code !== 200) reject(response.data.message)
            this.editEquipmentId = response.data.data.id
            this.editEquipment = response.data.data
            resolve(true)
          }).catch(error => {
            reject(error)
          })
        })
      },
      editPart (item) {
        this.editEquipmentPartIndex = this.equipmentParts.indexOf(item)
        this.editEquipmentPart = Object.assign({}, item)
      },
      deletePart (item) {
        if (item.id && item.id > 0) {
          this.requestRemovePartArray.push(item)
        }
        const index = this.equipmentParts.indexOf(item)
        this.equipmentParts.splice(index, 1)
      },
      resetPart () {
        this.editEquipmentPartIndex = -1
        this.editEquipmentPart = Object.assign({}, this.defaultEquipmentPart)
      },
      savePart () {
        if (this.editEquipmentPartIndex > -1) {
          Object.assign(this.equipmentParts[this.editEquipmentPartIndex], this.editEquipmentPart)
        } else {
          this.equipmentParts.push(this.editEquipmentPart)
        }
        this.resetPart()
      },
      async uploadParts () {
        await this.equipmentParts.forEach((element, key) => {
          if (element.id > -1) {
            this.updateRecordParts(element)
          } else {
            this.storeRecordParts(element)
          }
        })
        await this.requestRemovePartArray.forEach((element) => {
          equipmentRecordPartApi.delete(element.id)
        })
        await this.checkConfigurationList()
        await this.loadRecord(this.editEquipmentId)
        this.process = 2
      },
      checkConfigurationList () {
        if (this.editEquipment.equipment_configuration_list_id > 0) {
          configListApi.checkConfiguration(
            { type: 'updated', equipment_id: this.editEquipment.id },
          )
        } else {
          configListApi.checkConfiguration(
            { type: 'created', equipment_id: this.editEquipment.id },
          )
        }
      },
      updateRecordParts (element) {
        equipmentRecordPartApi.update(element)
      },
      storeRecordParts (element) {
        equipmentRecordPartApi.store(this.editEquipmentId, element)
      },
      updateImagesData (value) {
        if (value.method === 'add') {
          if (this.editEquipment.images === null) this.editEquipment.images = []
          this.editEquipment.images.push(value.imageInfo)
        }
        if (value.method === 'delete') {
          //  index = this.editEquipment.images.indexOf(value.url)
          const index = this.editEquipment.images.findIndex(obj => obj.file_path === value.imageInfo.file_path)
          this.editEquipment.images.splice(index, 1)
          this.updateRecord()
        }
        if (value.method === 'cloud') {
          this.updateRecord()
        }
      },
      createAnother () {
        this.resetData()
        this.process = 0
      },
      close () {
        this.resetData()
        this.$emit('updatedRecord')
        this.createRecordDialog = false
      },
      resetData () {
        this.$nextTick(() => {
          this.editEquipmentPart = Object.assign({}, this.defaultEquipmentPart)
          this.editEquipment = Object.assign({}, this.defaultEquipment)
          this.equipmentParts = []
          this.editEquipmentId = -1
          this.editEquipmentPartIndex = -1
          // this.editEquipmentIndex = -1
          this.process = 0
          this.requestRemovePartArray = []
        })
      },
    },
  }
</script>

<style scoped>

</style>
