<template>
  <v-card-text>
    <v-dialog
      v-model="zoomInPictureDialog"
      width="720"
    >
      <v-card>
        <v-card-title class="heading">
          ID:{{ zoomPicture.id }} Name: {{ zoomPicture.name }}
        </v-card-title>
        <v-card-text>
          <v-img
            :alt="zoomPicture.name"
            :src="zoomPicture.url"
          >
          </v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card-actions>
      <v-file-input
        v-model="uploadImages"
        label="Image Upload"
        accept="image/png, image/jpeg, image/bmp"
        prepend-icon="mdi-camera"
        multiple
        dense
      />
      <v-btn
        :loading="imagesUploadProcessing"
        :disabled="imagesUploadProcessing"
        color="blue-grey"
        class="ma-2 white--text"
        @click="imageUpload()"
      >
        Upload Image
        <v-icon
          right
          dark
        >
          mdi-cloud-upload
        </v-icon>
      </v-btn>
    </v-card-actions>
    <v-card-title>Pictures Library</v-card-title>
    <v-card>
      <v-virtual-scroll
        :items="publicImages"
        height="200"
        :item-height="imageListScrollHeight"
        v-if="publicImages.length >0"
      >
        <template v-slot:default="{ item }">
          <v-list-item>
            <v-row>
              <v-col
                cols="12"
                md="3"
              >
                <h5>
                  ID: {{ item.id }}
                </h5>
                <v-img
                  max-height="80"
                  max-width="200"
                  :alt="item.name"
                  :src="item.url"
                  @click="zoomInPicture(item)"
                />
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <h5>Name: </h5>
                <h5 class="mt-3">
                  {{ item.name }}
                </h5>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <h5>Actions: </h5>
                <v-card-actions class="mt-3">
                  <v-btn
                    depressed
                    @click="copyToClipboard(item.url)"
                  >
                    Copy Url
                  </v-btn>
                  <v-btn
                    depressed
                    class="ml-3"
                    color="red"
                    @click="imageDelete(item)"
                  >
                    Delete
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-list-item>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-card-text>
</template>

<script>
  import ItemApi from '@/api/item'
  import store from '@/store'
  export default {
    name: 'ImageEdit',
    props: {
      itemId: Number,
    },
    data () {
      return {
        zoomInPictureDialog: false,
        zoomPicture: {
          url: '',
          name: '',
        },
        imagesUploadProcessing: false,
        uploadImages: null,
        publicImages: [],
        imageListScrollHeight: 100,
      }
    },
    mounted () {
      window.addEventListener('resize', this.imageListScrollHeightHandle)
      this.getImagesList()
    },
    methods: {
      // image basic function
      imageListScrollHeightHandle () {
        window.innerWidth < 960 ? this.imageListScrollHeight = 300 : this.imageListScrollHeight = 100
      },
      copyToClipboard (url) {
        navigator.clipboard.writeText(url)
      },
      // picture load and show
      getImagesList () {
        ItemApi.publicImageList(this.itemId).then(response => {
          this.publicImages = response.data.data
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        })
      },
      zoomInPicture (object) {
        this.zoomPicture = Object.assign({}, object)
        this.zoomInPictureDialog = true
      },
      // picture actions
      async imageUpload () {
        if (this.uploadImages === null || this.uploadImages.length === 0) return // eslint-disable-line
        this.imagesUploadProcessing = true
        for (const element of this.uploadImages) {
          const imageData = new FormData()
          imageData.append('item_image', element)
          imageData.append('item_id', this.itemId.toString())
          await ItemApi.publicImageUpload(imageData).then(response => {
            this.getImagesList()
          }).catch(error => {
            store.set('message/storeMessage', {
              color: error.response.data.type,
              text: error.response.data.message,
              timeout: 3500,
            })
          })
        }
        this.imagesUploadProcessing = false
        this.uploadImages = null
      },
      imageDelete (imageInfo) {
        ItemApi.publicImageDelete({ id: imageInfo.id }).then(response => {
          this.getImagesList()
        }).catch(error => {
          store.set('message/storeMessage', {
            color: error.response.data.type,
            text: error.response.data.message,
            timeout: 3500,
          })
        })
      },
    },
  }
</script>

<style scoped>

</style>
