<template>
  <v-card>
    <snack-bar ref="snackBar" />
    <v-row>
      <v-col cols="12">
        <v-form class="ma-2">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="editItem.part_number"
                label="Part Number *"
                required
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="editItem.specify_keyword"
                label="Specify Keyword"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="editItem.bin_number"
                label="BIN *"
                required
              />
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="editItem.description"
                label="Description"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="editItem.is_sos_item"
                label="Check, If item is or will be SOS Item *"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="editItem.itemType"
                label="Item Type *"
                :items="itemTypes"
                item-text="displayText"
                item-value="id"
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
            >
              <v-btn
                block
                @click="close"
              >
                Reset
              </v-btn>
            </v-col>
            <v-col
              cols="6"
            >
              <v-btn
                block
                color="primary"
                @click="createItem"
              >
                Create
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import store from '@/store'
  import { get } from 'vuex-pathify'
  import itemApi from '@/api/item'
  import SnackBar from '@/components/app/SnackBar'
  export default {
    name: 'Create',
    components: { SnackBar },
    computed: {
      ...get('app/', ['itemTypes']),
    },
    data () {
      return {
        editItem: {
          part_number: '',
          description: '',
          item_type_id: '',
          // bin_number_id: '',
          bin_number: '',
          specify_keyword: '',
          is_sos_item: false,
        },
        defaultItem: {
          part_number: '',
          description: '',
          item_type_id: '',
          // bin_number_id: '',
          bin_number: '',
          specify_keyword: '',
          is_sos_item: false,
        },
        editItemType: {

        },
        defaultItemTpe: {

        },
      }
    },
    mounted () {
      store.set('app/getItemTypes')
    },
    methods: {
      createItem () {
        itemApi.store(this.editItem).then(response => {
          if (response.data.code === 200) {
            store.set('message/storeMessage', {
              color: 'primary',
              text: 'Create successful',
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
            this.close()
          } else {
            store.set('message/storeMessage', {
              color: 'red',
              text: 'Something was wrong, can\'t create item',
              timeout: 3000,
            })
            this.$refs.snackBar.activeStatusInfo()
          }
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'red',
            text: error,
            timeout: 3000,
          })
          this.$refs.snackBar.activeStatusInfo()
        })
      },
      close () {
        this.editItem = Object.assign({}, this.defaultItem)
      },
    },
  }
</script>

<style scoped>

</style>
