import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index (itemTableFilter) {
    let search
    itemTableFilter.keyWord === null ? search = 'All' : search = itemTableFilter.keyWord
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'item',
      params: {
        key_word: search,
        item_type_id: itemTableFilter.itemTypeId,
        order: itemTableFilter.order,
      },
    })
  },
  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'item',
      data: {
        part_number: data.part_number,
        description: data.description,
        item_type_id: data.item_type_id,
        bin_number: data.bin_number,
        specify_keyword: data.specify_keyword,
      },
    })
  },
  show (id) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'item/' + id,
    })
  },
  update (data) {
    return axios({
      method: 'put',
      url: apiConfig.apiUri + 'item/' + data.id,
      data: {
        item_type_id: data.item_type_id ?? null,
        item_category_id: data.itemCategoryId ?? data.item_category_id,
        specify_keyword: data.specifyKeyword ?? null,
        long_description: data.long_description ?? null,
      },
    })
  },
  syncItemFromSos (id) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'autoSync/itemSync',
      data: {
        id: id,
      },
    })
  },
  loadPageData (page, url, itemTableFilter) {
    let search
    itemTableFilter.keyWord === null ? search = 'All' : search = itemTableFilter.keyWord
    return axios({
      method: 'get',
      url: url + '?page=' + page,
      params: {
        key_word: search,
        item_type_id: itemTableFilter.itemTypeId,
        order: itemTableFilter.order,
      },
    })
  },
  quickSearch (keyword) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'item/quickSearchItem',
      params: {
        keyword: keyword,
      },
    })
  },

  advancedSearch (data) {
    let properties = null
    if (data.property_filter.length > 0) properties = JSON.stringify(data.property_filter)
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'item/advancedSearch',
      params: {
        item_category_id: data.item_category_id,
        property_filter: properties,
      },
    })
  },
  advancedSearchPageData (url) {
    return axios({
      method: 'get',
      url: url,
    })
  },

  publicImageList (itemId) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'item/publicImageList/' + itemId,
    })
  },
  publicImageUpload (formData) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'item/publicImageUpload',
      data: formData,
    })
  },
  publicImageDelete (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'item/publicImageDelete',
      data,
    })
  },
}
