import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import axios from 'axios'
import VueAxios from 'vue-axios'
import moment from 'moment'
import Echo from 'laravel-echo'

moment.locale(navigator.language)

Vue.config.productionTip = false

window.Pusher = require('pusher-js')
window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_MIX_PUSHER_APP_KEY, // process.env.MIX_PUSHER_APP_KEY,
  cluster: process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER, // process.env.VUE_APP_MIX_PUSHER_APP_CLUSTER,
  forceTLS: true,
  auth: {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('userAuthData') == null ? localStorage.getItem('userAuthData').access_token : null,
    },
  },
})

sync(store, router)
Vue.use(VueAxios, axios)

new Vue({
  router,
  vuetify,
  store,
  created () {
    const userInfo = localStorage.getItem('userAuthData')
    if (userInfo) {
      const userData = JSON.parse(userInfo)
      store.set('user/storeAuth', userData)
    }
    axios.interceptors.response.use(
      response => {
        if (response.data.code === 401) {
          store.set('user/clean').then(() => {
            if (window.location.pathname !== 'Login') router.push('/login').catch(() => {})
          })
        } else {
          return response
        }
      },
      error => {
        if (error.response.status === 401) {
          store.set('user/clean').then(() => {
            if (window.location.pathname !== 'Login') router.push('/login').catch(() => {})
          })
        }
        if (error.response.status === 422) {
          console.log(error.response.status)
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.errors.value.toString(),
            timeout: 3500,
          })
        }
        return Promise.reject(error)
      },
    )
  },
  render: h => h(App),
}).$mount('#app')
