let apiUri = ''
switch (process.env.NODE_ENV) {
  case 'development':
        apiUri = ' http://testlogbook.test/api/v1/' // http://192.168.10.89/api/v1/
        break
    case 'production':
        apiUri = process.env.VUE_APP_BACKEND_LINK
        break
}

export const apiConfig = {
    apiUri: apiUri,
}
