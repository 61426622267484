import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
    show (id) {
        return axios({
            method: 'get',
            url: apiConfig.apiUri + 'itemReceiptItem' + '/' + id,
        })
    },
  update (data) {
      return axios({
        method: 'put',
        url: apiConfig.apiUri + 'itemReceiptItem' + '/' + data.id,
        data: data,
      })
  },
  store (data) {
     return axios({
       method: 'post',
       url: apiConfig.apiUri + 'itemReceiptItem',
       data: data,
     })
  },
  delete (id) {
      return axios({
        method: 'delete',
        url: apiConfig.apiUri + 'itemReceiptItem/' + id,
      })
  },
  resetStatus (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'ItemReceiptItem/resetTaskStatus',
      data,
    })
  },
  itemsChange (itemReceiptId, removeData, changeData, newData) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemReceiptItem/receiptChange',
      data: {
        item_receipt_id: itemReceiptId,
        remove_items: removeData,
        change_items: changeData,
        new_items: newData,
      },
    })
  },
}
