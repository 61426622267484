// Pathify
import { make } from 'vuex-pathify'

const state = {
  message: {
    color: '',
    text: '',
    timeout: 3000,
  },
}

const mutations = make.mutations(state)

const actions = {
  setStoreMessage: ({ commit }, data) => {
    return new Promise(() => {
      commit('message', data)
    })
  },
}

const getters = {
  message: state => {
    return state.message
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
