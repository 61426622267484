<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  // Styles
  import '@/styles/overrides.sass'
  import dashBoardApi from './api/dashboard-statistics'
  import { error } from './util/routes'
  import axios from 'axios'
  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: '%s | Testing Log',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    beforeUpdate: function () {
      this.loadVersion()
    },
    beforeRouteEnter () {
      axios.interceptors.request.use(req => {
        if (!req.headers.ClientIP) {
          fetch('https://api.ipify.org/?format=json')
            .then(response => response.json())
            .then(data => (
              req.headers.ClientIP = data.ip
            ))
        }
        return req
      })
    },
    methods: {
      loadVersion () {
        dashBoardApi.systemVersion().then(response => {
          const currentVersion = localStorage.getItem('version')
          if (currentVersion) {
            if (response.data.data !== currentVersion) {
              localStorage.setItem('version', response.data.data)
              location.reload()
            }
          } else {
            localStorage.setItem('version', response.data.data)
          }
          this.$nextTick(() => {
            console.log(currentVersion) // DOESN'T GET HERE
          })
        }).catch(error => {
          console.log(error)
        })
      },
    },
  }
</script>
