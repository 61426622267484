import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  index (data) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemEcommerceId',
      params: data,
    })
  },

  showRelated (data) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemEcommerceId/selectRelatedShow',
      params: data,
    })
  },

  store (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemEcommerceId',
      data: data,
    })
  },

  updateRelated (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemEcommerceId/selectRelatedUpdate',
      data: data,
    })
  },

  destroyRelated (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemEcommerceId/selectRelatedDestroy',
      data: data,
    })
  },
}
