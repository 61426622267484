// Pathify
import { make } from 'vuex-pathify'
import itemTypeApi from '@/api/item-type'

// Data
const state = {
  drawer: null,
  drawerImage: true,
  mini: false,
  showDoneTestingDashboard: true, // location in RealTimeTestingUpdateBoard
  items: [
    {
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      to: '/',
    },
    {
      title: 'Calendar',
      icon: 'mdi-calendar-text',
      to: '/calendar',
    },
    {
      title: 'Item Receipt Tables',
      icon: 'mdi-clipboard-outline',
      to: '/tables/itemReceipt/',
    },
    {
      title: 'Item List',
      icon: 'mdi-table-of-contents',
      to: '/tables/items/',
    },
    {
      title: 'Equipment Status',
      icon: 'mdi-dns',
      to: '/tables/equipmentRecord/',
    },
    {
      title: 'Configuration List',
      icon: 'mdi-dns',
      to: '/tables/configurationList',
    },
    {
      title: 'Testing Manual',
      icon: 'mdi-book-open-page-variant-outline',
      to: '/tables/manual/',
    },
    {
      title: 'Sales RMA',
      icon: 'mdi-chart-tree',
      to: '/rma/SalesRma',
    },
    /*
    {
      title: 'Typography',
      icon: 'mdi-format-font',
      to: '/components/typography/',
    },
    {
      title: 'Icons',
      icon: 'mdi-chart-bubble',
      to: '/components/icons/',
    },
    {
      title: 'Google Maps',
      icon: 'mdi-map-marker',
      to: '/maps/google/',
    },
    {
      title: 'Notifications',
      icon: 'mdi-bell',
      to: '/components/notifications/',
    },
    */
  ],
  itemTypes: [],
  /*
  itemTypeCategory: [
    'Memory', 'HDD', 'SSD', 'Storage', 'SFP', 'Networking', 'Processor', 'GPU',
    'Other', 'Server Part', 'Server', 'Switch', 'Switch Part', 'Router', 'Router Part',
  ],
  */
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
  setGetItemTypes: ({ commit }) => {
    return new Promise((resolve, reject) => {
      itemTypeApi.index().then(response => {
        const result = response.data.data
        result.forEach((element) => {
          element.displayText = 'Type: ' + element.name + ' - ' + 'Category: ' + element.category
        })
        result.push({ id: 0, displayText: 'All' })
        commit('itemTypes', result)
        resolve(true)
      }).catch(error => {
        reject(error)
      })
    })
  },
  setShowDoneTestingDashboard: ({ commit }, data) => {
    commit('showDoneTestingDashboard', data)
  },
}

const getters = {
  itemTypes: state => {
    return state.itemTypes
  },
  showDoneTestingDashboard: state => {
    return state.showDoneTestingDashboard
  },
  /*
  itemTypeCategory: state => {
    return state.itemTypeCategory
  },
  * */
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
