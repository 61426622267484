<template>
  <v-row justify="center">
    <v-dialog
      v-model="ItemChangeReviewDialog"
      max-width="1000"
      persistent
      scrollable
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="ItemChangeReviewDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="updateItemReceipt()"
            >
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div v-if="changeItems.remove_items !== 'none'">
                <v-card-title class="text-h5 text--primary font-weight-bold">
                  Removed items Group
                </v-card-title>
                <v-card
                  v-for="(removeItem, index) in changeItems.remove_items"
                  :key="index"
                >
                  <v-card-text class="text--primary">
                    ID: {{ removeItem.id }} Part Number: {{ removeItem.item_part_number }} Description: {{ removeItem.item_description }}
                    <br>
                    BIN: <span v-if="removeItem.item !==null">{{ removeItem.item.bin_number }}</span><span v-else> None </span>
                    Qty: {{ removeItem.label_show_quantity }} Status: {{ removeItem.task_status.name }}
                  </v-card-text>
                  <v-card-actions>
                    <v-text-field
                      v-model="removeItem.toReceipt"
                      placeholder="'remove' use to delete but no need move"
                      label="Item Move to Receipt Number"
                      clearable
                      required
                    />
                    <v-spacer />
                    <v-btn
                      color="error"
                      text
                      @click="addItemToGroup(index,'remove')"
                    >
                      Add to Remove
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-card>
                  <v-data-table
                    dense
                    :headers="removeDataTableHeader"
                    :items="confirmRemove"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="removeItemFromGroup(item, 'remove')"
                      >
                        mdi-close-thick
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </div>
              <div v-if="changeItems.change_items !== 'none'">
                <v-card-title class="text-h5 text--primary font-weight-bold">
                  Change items Group
                </v-card-title>
                <v-card
                  v-for="(changeItem, index) in changeItems.change_items"
                  :key="index"
                >
                  <v-card-text class="text--primary">
                    <h5>Before Change</h5>
                    <br>
                    ID: {{ changeItem.in_database.id }} Part Number: {{ changeItem.in_database.item_part_number }} Description: {{ changeItem.in_database.item_description }}
                    <br>
                    BIN: <span v-if="changeItem.in_database.item !==null">{{ changeItem.in_database.item.bin_number }}</span><span v-else> None </span>
                    Qty: {{ changeItem.in_database.label_show_quantity }} Status: {{ changeItem.in_database.task_status.name }}
                  </v-card-text>
                  <v-card-text class="text--primary">
                    <h5>After Change</h5>
                    <br>
                    ID: {{ changeItem.in_database.id }} Part Number: {{ changeItem.from_sos.item.name }} Description: {{ changeItem.from_sos.description }}
                    <br>
                    BIN: Auto Acquire After change
                    Qty: {{ changeItem.from_sos.quantity }} Status: {{ changeItem.in_database.task_status.name }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="addItemToGroup(index,'change')"
                    >
                      Add to Change
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-data-table
                  dense
                  :headers="changeDataTableHeader"
                  :items="confirmUpdate"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="removeItemFromGroup(item, 'change')"
                    >
                      mdi-close-thick
                    </v-icon>
                  </template>
                </v-data-table>
              </div>
              <div v-if="changeItems.new_items !== 'none'">
                <v-card-title class="text-h5 text--primary font-weight-bold">
                  New items Group
                </v-card-title>
                <v-card
                  v-for="(newItem, index) in changeItems.new_items"
                  :key="index"
                >
                  <v-card-text class="text--primary">
                    Line Number: {{ newItem.lineNumber }} Part Number: {{ newItem.item.name }} Description: {{ newItem.description }}
                    <br>
                    BIN: Auto Acquire After change
                    Qty: {{ newItem.quantity }} Status: Created
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      text
                      @click="addItemToGroup(index,'new')"
                    >
                      Add to New
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-data-table
                  dense
                  :headers="newDataTableHeader"
                  :items="confirmNew"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="removeItemFromGroup(item, 'new')"
                    >
                      mdi-close-thick
                    </v-icon>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import receiptItemApi from '../../api/item-receipt-item'
  export default {
    name: 'ItemChangeReview',
    props: { changeItems: Object, receiptId: Number },
    data () {
      return {
        ItemChangeReviewDialog: false,
        step: 0,
        confirmRemove: [],
        confirmUpdate: [],
        confirmNew: [],
        removeDataTableHeader: [
          { text: 'Remove: PN', value: 'item_part_number' },
          { text: 'BIN', value: 'item.bin_number ' },
          { text: 'To', value: 'toReceipt' },
          { text: '| Actions', value: 'actions', sortable: false },
        ],
        changeDataTableHeader: [
          { text: 'Before: PN', value: 'in_database.item_part_number' },
          { text: 'Qty', value: 'in_database.label_show_quantity' },
          { text: 'Status', value: 'in_database.task_status.name' },
          { text: 'New: PN', value: 'from_sos.item.name' },
          { text: 'Qty', value: 'from_sos.quantity' },
          { text: 'Status', value: 'in_database.task_status.name' },
          { text: '| Actions', value: 'actions', sortable: false },
        ],
        newDataTableHeader: [
          { text: 'New: PN', value: 'item.name' },
          { text: 'Description', value: 'description' },
          { text: 'Qty', value: 'quantity' },
          { text: '| Actions', value: 'actions', sortable: false },
        ],
      }
    },
    watch: {
      ItemChangeReviewDialog (newVal) {
        if (newVal === true) this.listItems()
      },
    },
    methods: {
      listItems () {
        console.log(this.changeItems)
      },
      updateItemsInfo () {
        // updateItemsInfo
      },
      addItemToGroup (key, group) {
        switch (group) {
          case 'remove':
            this.confirmRemove[key] = Object.assign({}, this.changeItems.remove_items[key])
            this.confirmRemove.push()
            break
          case 'change':
            this.confirmUpdate[key] = Object.assign({}, this.changeItems.change_items[key])
            this.confirmUpdate.push()
            break
          case 'new':
            this.confirmNew[key] = Object.assign({}, this.changeItems.new_items[key])
            this.confirmNew.push()
            break
        }
        console.log(this.confirmRemove)
      },
      removeItemFromGroup (item, group) {
        console.log(item)
        switch (group) {
          case 'remove':
            this.confirmRemove = this.confirmRemove.filter(function (element) { return element.id !== item.id })
            break
          case 'change':
            this.confirmUpdate = this.confirmUpdate.filter(function (element) { return element.in_database.id !== item.in_database.id })
            break
          case 'new':
            this.confirmNew = this.confirmNew.filter(function (element) { return element.id !== item.id })
            break
        }
      },
      updateItemReceipt () {
        const removeData = []
        const changeData = []
        const newData = []
        this.confirmRemove.forEach((item, index) => {
          removeData.push({ id: item.id, toReceipt: item.toReceipt })
        })
        this.confirmUpdate.forEach((item, index) => {
          changeData.push({
            id: item.in_database.id,
            sos_item_id: item.from_sos.item.id,
            item_part_number: item.from_sos.item.name,
            description: item.from_sos.description,
            label_show_quantity: item.from_sos.quantity,
          })
        })
        this.confirmNew.forEach((line, index) => { newData.push(line) })
        receiptItemApi.itemsChange(this.receiptId, removeData, changeData, newData).then(response => {
          if (response.data.code === 200) this.ItemChangeReviewDialog = false
        }).catch(error => {
          console.log(error)
        })
      },
    },
  }
</script>

<style scoped>

</style>
