import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
    index (filter) {
      return axios({
        method: 'get',
        url: apiConfig.apiUri + 'itemReceipt',
        params: {
          category_id: filter.categoryId,
          task_status_id: filter.taskStatusId,
          key_word: filter.key_word,
          order: filter.order,
          date_from: filter.date_from,
        },
      })
    },
    show (id) {
        return axios({
            method: 'get',
            url: apiConfig.apiUri + 'itemReceipt' + '/' + id,
        })
    },
  update (data) {
      return axios({
        method: 'put',
        url: apiConfig.apiUri + 'itemReceipt' + '/' + data.id,
        data: data,
      })
  },
  syncData () {
      return axios({
        method: 'get',
        url: apiConfig.apiUri + 'autoSyncDataItemReceipt',
      })
  },
  syncItemReceipt (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemReceipt/syncFromSos',
      data: {
        sos_receipt_number: data.sos_receipt_number,
        type: data.type,
        sos_receipt_id: data.sos_receipt_id,
      },
    })
  },
  syncReceiptStatus (type) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'itemReceiptStatusUpdate',
      params: {
        sync_type: type,
      },
    })
  },
  syncDoneInTesting () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'realtimeBoardDoneTestingMessage',
    })
  },
  changeMarkInDashboard (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'realtimeBoard/changeMark',
      data: data,
    })
  },
  /*
  handleQuickCreateOrder (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'ItemReceipt/previewQuickManualOrder',
      data: {
        data: data,
      },
    })
  },
  */
  storeManualOrder (receipt, receiptItems) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemReceipt',
      data: {
        receipt: receipt,
        receiptItems: receiptItems,
      },
    })
  },
  deleteItemReceipt (receiptId) {
    return axios({
      method: 'delete',
      url: apiConfig.apiUri + 'itemReceipt/' + receiptId,
    })
  },
  changeTaskStatus (data) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'itemReceipt/changeReceiptStatus',
      data: data,
    })
  },
}
