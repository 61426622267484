<template>
  <v-dialog
    v-model="dialog"
    width="350px"
  >
    <v-card>
      <v-card-title class="text-lg-h5">
        {{ title }}
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-col
            cols="12"
          >
            <v-select
              v-model="editEcommerceId.table_name"
              :items="tableNames"
              label="Select Type"
              :disabled="eCommerceId > 0"
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="editEcommerceId.value"
              label="eCommerce ID"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-switch
              v-model="editEcommerceId.is_active"
              label="Active"
            >
            </v-switch>
          </v-col>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="eCommerceId>0"
          :disabled="eCommerceIdUpdatingStatus"
          text
          color="red"
          @click="deleteEcommerceIdInfo()"
        >
          DELETE
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="resetData()"
        >
          Cancel
        </v-btn>
        <v-btn
          v-if="eCommerceId > 0"
          :disabled="eCommerceIdUpdatingStatus"
          text
          @click="updateEcommerceIdInfo()"
        >
          update
        </v-btn>
        <v-btn
          v-else
          :disabled="eCommerceIdUpdatingStatus"
          text
          @click="createEcommerceIdInfo()"
        >
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import ItemEcommerceApi from '@/api/item-ecommerce'
  import store from '@/store'
  export default {
    name: 'EcommerceIdCreateEdit',
    data () {
      return {
        dialog: false,
        tableNames: ['ebay', 'newegg'],
        eCommerceId: -1,
        tableName: '',
        defaultEcommerceId: {
          table_name: '',
          item_sos_id: '',
          value: '',
          is_active: false,
        },
        editEcommerceId: {
          table_name: '',
          item_sos_id: '',
          value: '',
          is_active: false,
        },
        eCommerceIdLoadingStatus: false,
        eCommerceIdUpdatingStatus: false,
      }
    },
    computed: {
      title () {
        return this.eCommerceId > 0 ? 'Edit eCommerce ID' : 'Create eCommerce ID'
      },
    },
    methods: {
      activeDialog (eCommerceId, tableName, itemSosId) {
        this.eCommerceId = eCommerceId
        this.tableName = tableName
        if (itemSosId !== null) this.editEcommerceId.item_sos_id = itemSosId
        if (this.tableName == null) {
          this.dialog = true
          return
        }
        this.loadEcommerceIdInfo(eCommerceId, tableName)
      },
      async loadEcommerceIdInfo (eCommerceId, tableName) {
        this.eCommerceIdLoadingStatus = true
        await ItemEcommerceApi.showRelated({ id: eCommerceId, table_name: tableName }).then(response => {
          this.editEcommerceId = Object.assign({}, response.data.data)
          // if (this.editEcommerceId.item !== null) this.editEcommerceId.item_id = this.editEcommerceId.item.id
          this.eCommerceIdLoadingStatus = false
          if (this.dialog === false) this.dialog = true
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
          this.resetData()
        })
      },
      createEcommerceIdInfo () {
        this.eCommerceIdUpdatingStatus = true
        ItemEcommerceApi.store(this.editEcommerceId).then(response => {
          //
        }).catch(error => {
          if (error.response.status === 422) return
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.eCommerceIdUpdatingStatus = false
          this.$emit('updateEcommerceIds')
          this.resetData()
        })
      },
      updateEcommerceIdInfo () {
        this.eCommerceIdUpdatingStatus = true
        ItemEcommerceApi.updateRelated(this.editEcommerceId).then(response => {
          // update ids table
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.eCommerceIdUpdatingStatus = false
          this.$emit('updateEcommerceIds')
          this.resetData()
        })
      },
      deleteEcommerceIdInfo () {
        this.eCommerceIdUpdatingStatus = true
        ItemEcommerceApi.destroyRelated(this.editEcommerceId).then(response => {
          // update ids table
        }).catch(error => {
          store.set('message/storeMessage', {
            color: 'error',
            text: error.response.data.message,
            timeout: 3500,
          })
        }).finally(() => {
          this.eCommerceIdUpdatingStatus = false
          this.$emit('updateEcommerceIds')
          this.resetData()
        })
      },
      resetData () {
        this.dialog = false
        this.$nextTick(() => {
          this.eCommerceId = -1
          this.tableName = ''
          this.editEcommerceId = Object.assign({}, this.defaultEcommerceId)
        })
      },
    },
  }
</script>

<style scoped>

</style>
