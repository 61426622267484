import { apiConfig } from '../plugins/config'
import axios from 'axios'

export default {
  userFollowItems () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'userFollowItems',
    })
  },
  requestDueItems () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'requestDueItems',
    })
  },
  testReport () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'testReportDashboard',
    })
  },
  summaryReport () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'summaryReport',
    })
  },
  customTestReport (date) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'customTestReport',
      data: {
        dateFrom: date.dateFrom,
        dateTo: date.dateTo,
      },
    })
  },
  summaryItemTypeTestReport (date) {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'testingResultItemTypeReport',
      data: {
        dateFrom: date.dateFrom,
        dateTo: date.dateTo,
      },
    })
  },
  systemVersion () {
    return axios({
      method: 'get',
      url: apiConfig.apiUri + 'app/version',
    })
  },
}
